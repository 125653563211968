<template>
  <div>
    <div ref="chat"><div class="flix-text-center"><flixLoader /></div></div>
  </div>
</template>
<script>

import { externalChat } from '@/components/chat/chatUpdate.js'
import md5 from 'js-md5'

export default {

  props: { user: String, form: [Number, String], entryID: String },
  data () {
    return {
      openChat: false,
      bot: false,
      msg: 0,
      time: false
    }
  },
  methods: {
    updateChat () {
      var send = {
        user: md5('' + this.bot.user_id + ''),
        form: this.form,
        entryID: this.entryID,
        chat_user: this.getActiveUser()
      }
      this.$flix_post({
        data: send,
        url: 'userchat/update_status',
        callback: function (ret) { }
      })
    },
    insertAnswer (msg) {
      var send = {
        user: md5('' + this.bot.user_id + ''),
        data: {
          chat_nr: this.user + '/' + this.form + '/' + this.entryID,
          msg: msg.msg,
          user: msg.userID,
          view: JSON.stringify([msg.userID])
        }
      }

      this.$flix_post({
        url: 'userchat/set_msg',
        data: send,
        callback: function (ret) {
          this.msg = this.msg + 1
          this.time = ret.data[2]
        }.bind(this)
      })
    },
    loadChat (callback) {
      this.$flix_post({
        url: 'userchat/get',
        data: {
          user: this.user,
          form: this.form,
          entryID: this.entryID,
          time: this.time,
          chatData: {}
        },
        callback: function (ret) {
          var data = ret.data[0]
          if (ret.data[0]) {
            data = ret.data[1]

            Object.keys(ret.data[1]).forEach(function (k) {
              ret.data[1][k].view = JSON.parse(ret.data[1][k].view)
            })
            this.time = ret.data[2]
          }
          callback(data)
        }.bind(this)
      })
    },
    getColors (ret) {
      var colors = this.$store.getters.colors

      this.chatData.users = {
        bot: {
          userID: 'bot', name: this.bot.username.toUpperCase(), background: colors[0], color: '#fff'
        },
        user: {
          userID: 'user', name: this.$t('message.user').toUpperCase(), background: colors[1], color: '#fff'
        },
        system: {
          userID: 'system', name: 'terminflix', background: colors[2], color: '#fff', avatar: this.$flix_url + '/assets/img/chat/Info.jpg'
        }
      }
    },
    getBotName (callback) {
      this.$flix_post({
        url: 'user/get_user',
        data: {
          user: this.user
        },
        callback: function (ret) {
          if (!ret.data[0]) {
            return false
          }
          this.bot = ret.data[1]
          callback()
        }.bind(this)
      })
    },
    getActiveUser () {
      var user = this.$getUserVariables()
      var activeUser = 'user'
      if (user.user.md5_id === this.user) {
        activeUser = 'bot'
      }
      return activeUser
    },
    getChat (ret) {
      if (typeof ret === 'undefined') {
        return false
      }

      this.chatData = {
        messages: [],
        users: {},
        language: this.$i18n.locale,
        activeUser: this.getActiveUser(),
        legend: ['bot', 'user'],
        update: {
          interval: (1000 * 10),
          callback: function (push) {
            this.loadChat(function (ret) {
              if (ret && ret.length) {
                ret = this.getMessages(ret)
                push.message(ret)
              }
            }.bind(this))
          }.bind(this)
        },
        onSubmit: function (entry) {
          this.insertAnswer(entry)
        }.bind(this)
      }

      this.getColors()
      if (ret && typeof ret === 'object' && ret.length) {
        this.msg = ret.length
        this.chatData.messages = this.getMessages(ret)
      }

      new externalChat(function () {}).run(this.chatData, this.$refs.chat)
    },
    getMessages (ret) {
      var msg = []
      ret.forEach(function (val) {
        msg.push({
          userID: val.user,
          view: val.view,
          msg: val.msg,
          date: val.date
        })
      })
      msg = msg.reverse()
      return msg
    },
    reloadChat () {
      this.getBotName(function () {
        this.loadChat(function (data) {
          this.updateChat()
          this.getChat(data)
        }.bind(this))
      }.bind(this))
    }
  },
  mounted () {
    this.reloadChat()
  }
}

</script>

<style lang="sass">
  #jj_chat_area .msg_area, #jj_chat_area .translation_area, #jj_chat_area .insert_area, #jj_chat_area .writing_area, #jj_chat_area .pause_area
    width: 100% !important
</style>
